<template>
  <div class="view-home">
    <!-- <MyHeader /> -->
    <div class="banner-box">
      <div class="bg-img-block">
        <div class="bg-img-scale">
          <img
            class="w-100 h-100 bg-img"
            :key="bannerActive"
            :src="bannerList[bannerActive].name"
            alt="" />
        </div>
      </div>
      <div @click="leftBtnOnClick" class="l-r-icon left-icon flex-c point">
        <div class="left-arrow">
          <div class="iconfont iconfont-l icon-iconset0415"></div>
        </div>
      </div>
      <div @click="rightBtnOnClick" class="l-r-icon right-icon flex-c point">
        <div class="right-arrow">
          <div class="iconfont iconfont-r icon-iconset0415"></div>
        </div>
      </div>
      <div class="float-text-box">
        <div class="row1 gsap1 flex-s">
          <div class="color-yellow">
            {{ bannerList[bannerActive].row1 }}
            <span style="color: #fff">{{
              bannerList[bannerActive].row1end
            }}</span>
          </div>
        </div>
        <div class="row2 color-white">
          <div class="row-text">{{ bannerList[bannerActive].row2 }}</div>
          <div class="row-float-text"></div>
        </div>
        <div class="row3 color-white">
          <div class="row-text">{{ bannerList[bannerActive].row3 }}</div>
          <div class="row-float-text"></div>
        </div>
        <div class="row4 gsap2 color-white">
          {{ bannerList[bannerActive].row4 }}
        </div>
        <div class="row5 gsap2 btns flex-s">
          <div
            @click="buyNowBtnOnClick(bannerList[bannerActive].router)"
            class="btn btn1 point">
            buy now
          </div>
          <!-- <div class="btn btn2 point">Learn More</div> -->
        </div>
      </div>
    </div>
    <div class="introduce-list-box">
      <div class="introduce-list-wrap">
        <div class="introduce-list flex-s">
          <a
            class="introduce-item"
            v-for="(item, index) in introduceList"
            :key="index">
            <div class="rotate-0-block">
              <div class="img-box flex-c">
                <img class="img" :src="item.img" alt="" srcset="" />
                <img class="line" :src="item.line" alt="" srcset="" />
              </div>
              <div class="title">{{ item.title }}</div>
              <!-- <div class="content">{{ item.content }}</div> -->
            </div>
            <div class="rotate-0-block rotate-180-block">
              <div class="img-box flex-c">
                <img class="img" :src="item.img" alt="" srcset="" />
                <img class="line" :src="item.line" alt="" srcset="" />
              </div>
              <div class="title">{{ item.title2 }}</div>
              <!-- <div class="content">{{ item.content }}</div> -->
            </div>
          </a>
        </div>
      </div>
      <div class="goods-lists-wrap">
        <div class="goods-lists">
          <div
            v-for="item in homeGoods"
            :key="item.id"
            v-show="item.goods.length"
            class="goods-list-item">
            <div class="common-list-title">
              <div class="title-name">
                {{ item.name }}
              </div>
              <div class="title-line"></div>
            </div>

            <div class="list-box flex-b">
              <div
                @click="
                  $router.push({
                    path: `products/${vItem.keywords}`,
                    // path: '/MallDetail',
                    // query: { id: vItem.id },
                  })
                "
                v-for="vItem in item.goods.slice(0, 8)"
                :key="vItem.id"
                class="list-item point">
                <div class="item-img">
                  <img
                    class="w-100"
                    :src="vItem.image"
                    :alt="vItem.title"
                    srcset="" />
                </div>
                <div class="name elips-2">{{ vItem.title }}</div>
                <div class="item-bottom flex-b">
                  <div class="bottom-price text-c">
                    <div>${{ vItem.marketprice }}</div>
                  </div>
                  <div class="bottom-vip-price text-c">
                    <div>${{ vItem.price }}</div>
                    <div class="img">
                      <img
                        class="w-100 h-100"
                        src="@/assets/images/pc/pc-home-icon12.png"
                        alt=""
                        srcset="" />
                    </div>
                  </div>
                  <div
                    class="bottom-add flex-c point"
                    @click.stop="addToCarOnClick(vItem)">
                    ADD TO CART
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="why-choose-me flex-c">
      <div class="why-left">
        <div class="why-row1">
          <div class="btn">Hi,friend</div>
        </div>
        <div class="why-row2">
          why you should<span style="color: #ffb100">Choose us</span>
        </div>
        <div class="why-row3">
          Our team's goal is to provide the best prices and highest quality for
          every customer
        </div>
        <div class="why-row4">
          <div class="why-row4-list">
            <div
              v-for="(item, index) in chooseUsTextList"
              :key="index"
              class="why-row4-item flex-s">
              <div class="icon flex-c">
                <img src="../../assets/images/New/bg1.png" alt="" />
              </div>
              <div class="right-text">
                <div class="title">{{ item.title }}</div>
                <div class="content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="why-row5">
          <div class="btn">Get A Quote</div>
        </div> -->
      </div>
      <div class="why-right">
        <img
          class="w-100 h-100"
          src="../../assets/images/New/bg8.png"
          alt=""
          srcset="" />
      </div>
    </div>
    <div class="star-box">
      <!-- <div class="star-row1 flex-c">
        <div class="btn">Testimonials</div>
      </div> -->
      <div class="star-row2">
        <!-- Trusted by More Than 3k Clients Around<span style="color: #ffb100"
          >The World</span
        > -->
        What Our Customers Say
      </div>
      <!-- <div class="star-row3">
        Amet consectur adipiscing elit sed eiusmod ex tempor incididunt labore
        dolore magna aliquaenim ad minim veniam.
      </div> -->
      <div class="star-row4">
        <div class="star-box-list flex-b">
          <div
            v-for="(item, index) in customerSayList"
            :key="index"
            class="star-box-item">
            <div class="star-item-row1 flex-s">
              <div class="header-img-box">
                <img class="w-100 h-100" :src="item.imgUrl" alt="" />
                <div class="float-img">
                  <img
                    class="w-100 h-100"
                    src="../../assets/images/New/bg6.png"
                    alt="" />
                </div>
              </div>
              <div class="content-box">
                <div class="title">{{ item.name }}</div>
                <div class="content">{{ item.job }}</div>
              </div>
            </div>
            <div class="star-item-row2">
              {{ item.text }}
            </div>
            <div class="star-item-row3"></div>
            <div class="star-item-row4 flex-s">
              <div class="star-item-left">
                <!-- <img class="w-100 h-100" :src="item.imgUrl" alt="" /> -->
              </div>
              <div class="star-item-right">
                <img src="../../assets/images/New/bg7.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="float-car-confirm"
      :class="{ 'mb-float-car-confirm': isMinDevice }"
      v-show="floatCarShow">
      <div class="close-btn" @click="floatCarShow = false"></div>
      <div class="top flex-s">
        <div class="top-left">
          <img class="w100 h100" :src="floatCarItem.image" alt="" srcset="" />
        </div>
        <div class="top-right">
          <div class="name">
            {{ floatCarItem.title }}
          </div>
          <div class="tip">Added to your shopping cart</div>
        </div>
      </div>
      <div class="bottom-btn flex-b">
        <div class="btn1 flex-c" @click="goToCarOnClick">VIEW CART</div>
        <div @click="goToCarOnClick" class="btn1 flex-c">CHECK OUT</div>
      </div>
    </div>
  </div>
</template>

<script>
// import MyHeader from "@/views/WrapIndex/comp/header.vue";
import { mallHomeGoodsListApi, addCarApi } from "@/network/api.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { gsap } from "gsap";
export default {
  name: "Home",
  // components: { MyHeader },
  data() {
    return {
      Timer: null,
      Timer2: null,
      bannerActive: 0,
      customerSayList: [
        {
          name: "David Smith",
          job: "Finance",
          text: "“WOK is a nice website for me and my clients . I bought a lot of keys on the site and have a nice trip here . Great quality with cheaper price.“",
          imgUrl:
            "https://winofficekey.com/wp-content/themes/fluxa-pro/assets/images/resource/testi-2.png",
        },
        {
          name: "Lord Kone",
          job: "Finance",
          text: "“I do not trust that the site can offer me lower price than MS. but I did get the key . it was 100% working. nice job !”",
          imgUrl:
            "https://winofficekey.com/wp-content/themes/fluxa-pro/assets/images/resource/testi-3.png",
        },
        {
          name: "Nicolas Spacey",
          job: "Designer",
          text: "“the key works well without problems.that is amazing for me.Hands down the best website for keys.”",
          imgUrl:
            "https://winofficekey.com/wp-content/themes/fluxa-pro/assets/images/resource/testi-4.png",
        },
      ],
      chooseUsTextList: [
        {
          title: "18hours Online Service",
          content: "Our service hours are from GMT 5:00 to GMT 23:00",
        },
        {
          title: "Customer first approach",
          content:
            "If any key-related issues arise, we provide the best service to our customers first",
        },
        {
          title: "Strong quality control",
          content:
            "We have a strong product inventory and quality control team",
        },
      ],
      bannerList: [
        {
          name: require("@/assets/images/pc/pc-banner2.png"),
          row1: "Windows CD Key ",
          row1end: "Microsoft OS Product Key",
          row2: "CD Key Supplier",
          row3: "Lowest price！",
          row4: "Windows CD Key have been popular with users for years regarding stability and great user experience.",
          router: "/Windows",
        },
        {
          name: require("@/assets/images/pc/pc-banner1.png"),
          row1: "Microsoft Office Key ",
          row1end: "MS Office Product  Key",
          row2: "Office Key Provider",
          row3: "Best-Value !",
          row4: "Microsoft Office Key is the most comprehensive version of the popular office suite for its cost-effectiveness and stability.",
          router: "/Office",
        },
      ],
      floatCarItem: {},
      homeGoods: [],
      floatCarShow: false,
      introduceList: [
        {
          title: "Price Cheaper",
          title2: "We offer cheaper product key than MS",
          content:
            "We leverage premium resources and possess a distinct advantage in our channels, enabling our customers to purchase the highest quality products",
          img: require("@/assets/images/New/bg3.png"),
          line: require("@/assets/images/New/bg9.png"),
        },
        {
          title: "Quality Assurance",
          title2: "Our MS keys are 100% working",
          content:
            "We leverage the highest quality resources and are dedicated to ensuring that all our products meet the highest standards, providing our customers with keys they can trust",
          img: require("@/assets/images/New/bg5.png"),
          line: require("@/assets/images/New/bg9.png"),
        },
        {
          title: "Delivery faster",
          title2: "Clients can get keys within minutes after they paid",
          content:
            "We have robust inventory management and quick response times, ensuring that our customers can access the products they want without delay",
          img: require("@/assets/images/New/bg4.png"),
          line: require("@/assets/images/New/bg9.png"),
        },
        {
          title: "Service Stronger",
          title2: "We do offer nice after-sale sevice ",
          content:
            "We have a comprehensive service system and a dedicated customer support team, ensuring that all our customers enjoy the best purchasing experience possible",
          img: require("@/assets/images/New/bg2.png"),
          line: require("@/assets/images/New/bg9.png"),
        },
      ],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["isVip", "isLogin", "finalFuncImgs"]),
    ...mapState(["onlineUrl", "isMinDevice", "carList"]),
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.Timer2);
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup", "setShowTopCart"]),
    init() {
      this.getHomeGoods();
      this.bannerScrollFun();
    },
    buyNowBtnOnClick(router) {
      this.$router.push(router);
    },
    goToCarOnClick() {
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.$router.push("/cart");
    },
    async addToCarOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.isVip) {
        this.$message.error(
          "Please contact customer service to obtain VIP for free"
        );
        return false;
      }
      clearTimeout(this.Timer);
      const params = {
        goods_id: item.id,
        nums: 1,
      };

      try {
        const res = await addCarApi(params);
        await this.getCartList();
        this.setShowTopCart(true);
        this.floatCarShow = true;
        this.Timer = setTimeout(() => {
          this.floatCarShow = false;
        }, 3000);
        this.floatCarItem = item;
      } catch (error) {
        this.setShowTipPopup(true);
      }
    },
    async getHomeGoods() {
      const res = await mallHomeGoodsListApi();
      this.homeGoods = res.data;
    },
    bannerScrollFun() {
      clearInterval(this.Timer2);
      this.Timer2 = setInterval(() => {
        this.leftBtnOnClick();
      }, 5000);
    },
    leftBtnOnClick() {
      this.rightBtnOnClick();
    },
    rightBtnOnClick() {
      this.bannerScrollFun();
      gsap.to(".row-float-text", {
        right: "50%",
        duration: 0,
      });
      gsap.to(".bg-img-scale", {
        opacity: "1",
        scale: "1",
        duration: 0,
      });
      gsap.to(".bg-img-scale", {
        opacity: "1",
        scale: "1.05",
        duration: 0.8,
      });
      gsap.to(".row-float-text", {
        width: "100%",
        right: "0",
        duration: 0.7,
      });
      gsap.to(".row-text", {
        scaleX: 0,
        duration: 0.5,
        ease: "power1.inOut",
        transformOrigin: "center center",
      });
      gsap.to(".gsap1", {
        top: "-1rem",
        opacity: 0,
      });
      gsap.to(".gsap2", {
        bottom: "-1rem",
        opacity: 0,
      });
      setTimeout(() => {
        gsap.to(".row-text", {
          scaleX: 1,
          duration: 0,
          ease: "power1.inOut",
          transformOrigin: "center center",
        });
        gsap.to(".gsap1", {
          top: "0rem",
          opacity: 1,
        });
        gsap.to(".gsap2", {
          bottom: "0rem",
          opacity: 1,
        });
        gsap.to(".row-float-text", {
          width: "0",
          right: "0",
          duration: 0.7,
        });
      }, 1000);
      if (this.bannerActive === 0) {
        this.bannerActive = 1;
      } else {
        this.bannerActive = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.view-home {
  transform: translateY(-1.9rem);
  position: relative;
  z-index: 1;

  .banner-box {
    width: 100%;
    height: 8.2rem;
    position: relative;

    // background: rgba(0, 0, 0, 0.9);
    // background: url("https://winofficekey.com/wp-content/uploads/2024/09/1-1.png");
    // background-size: 100% 100%;
    .bg-img-block {
      background: #000;
      position: absolute;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      filter: brightness(0.5);
    }

    .l-r-icon {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      background-size: 100% 100%;

      &.left-icon {
        left: 0.4rem;
      }

      &.right-icon {
        right: 0.4rem;
      }

      .iconfont {
        position: absolute;
        right: -0.14rem;
        top: 50%;
        transform: translateY(-50%);
        color: var(--yellow);
        font-weight: bold;
        font-size: 0.4rem;
        transition: all 0.3s;
      }

      .iconfont-l {
        &:hover {
          right: 0;
        }
      }

      .iconfont-r {
        transform: translateY(-50%) rotateY(180deg);
        right: unset;
        left: -0.14rem;
      }
    }

    .l-r-icon:hover {
      .iconfont-l {
        right: 0.1rem;
      }

      .iconfont-r {
        left: 0.1rem;
      }
    }

    .float-text-box {
      font-family: PingFang SC, PingFang SC;
      font-size: 0.24rem;
      // width: 72%;
      // height: 60%;
      position: absolute;
      // bottom: 1.06rem;
      top: 2.61rem;
      left: 3.1rem;
      // transform: translateX(-50%);
      // padding: 0 0.1rem;
      box-sizing: border-box;
      .row1 {
        margin-bottom: 0.12rem;
      }
      .row2,
      .row3 {
        font-weight: 600;
        font-size: 0.8rem;
        width: fit-content;
        margin-bottom: 0.1rem;
        position: relative;
        .row-text {
          overflow: hidden;
          white-space: nowrap;
        }
        .row-float-text {
          position: absolute;
          right: 50%;
          top: 0;
          width: 0;
          height: 100%;
          background: #fff;
        }
      }
      .row3 {
        margin-bottom: 0.3rem;
      }
      .row4 {
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
      }
      .row5 {
        .btn {
          padding: 0.14rem 0.54rem;
          border-radius: 0.25rem;
          background: var(--yellow);
          color: var(--white);
          font-size: 0.16rem;
          font-weight: 600;

          &:hover {
            background: var(--white);
            color: #000;
          }
        }

        .btn2 {
          background: var(--white);
          color: #000;

          &:hover {
            background: var(--yellow);
            color: var(--white);
          }
        }
      }
      .row1,
      .row2,
      .row3,
      .row4,
      .row5 {
        position: relative;
      }
    }
  }

  .introduce-list-box {
    width: 100%;
    // background: pink;
    flex-wrap: nowrap;
    overflow-x: hidden;
    position: relative;
    top: -0.5rem;
    margin-bottom: 1.13rem;

    .introduce-list-wrap {
      width: 100%;
      padding: 0 3.1rem;
      box-sizing: border-box;
      margin-bottom: 0.8rem;
    }

    .introduce-list {
      width: 100%;
      // overflow: hidden;
      // margin-bottom: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .introduce-item {
        width: 22%;
        height: 3rem;
        padding: 0.32rem 0.23rem;
        flex-shrink: 0;
        // margin: 0 0.4rem;
        border-radius: 0.2rem;
        text-align: center;
        // padding: 0.1rem 0.2rem;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        perspective: 1000px;
        backface-visibility: hidden;
        transform-style: preserve-3d;

        .rotate-0-block {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: all 1s cubic-bezier(0.68, -0.95, 0.265, 1.55);
          background: #f4f9f5;
          transform: rotateY(0deg);
          z-index: 2;
          border-radius: 0.2rem;
          perspective: 1000px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          padding: 0.3rem 0.4rem;
          box-sizing: border-box;
          .img-box {
            width: 100%;
            height: 1.1rem;
            position: relative;
            margin-bottom: 0.17rem;
            /* 定义一个 keyframes 动画规则 */
            @keyframes rotate {
              from {
                transform: translate(-50%, -50%) rotate(0deg);
              }
              to {
                transform: translate(-50%, -50%) rotate(360deg);
              }
            }
            .img {
              width: 1.1rem;
              height: 1.1rem;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              top: 50%;
              z-index: 1;
            }
            .line {
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 2;
              animation: rotate 8s linear infinite; /* 动画名称、持续时间、速度曲线、重复次数 */
              transform: translate(-50%, -50%);
            }
          }
          .title {
            font-size: 0.24rem;
            color: #000000;
            margin-bottom: 0.12rem;
          }
          .content {
            font-size: 0.2rem;
            color: #666666;
          }
        }

        .rotate-180-block {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: all 1s cubic-bezier(0.68, -0.95, 0.265, 1.55);
          background: rgb(18, 18, 18, 0.85);
          transform: rotateY(180deg);
          z-index: 1;
          border-radius: 0.2rem;
          perspective: 1000px;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          color: #fff;
          padding: 0.3rem 0.4rem;
          box-sizing: border-box;
          .title {
            font-size: 0.24rem;
            color: #fff;
            margin-bottom: 0.12rem;
          }
          .content {
            font-size: 0.2rem;
            color: #ccc;
          }
        }

        &:hover {
          .rotate-0-block {
            transform: rotateY(180deg);
            z-index: 1;
          }

          .rotate-180-block {
            transform: rotateY(0deg);
            z-index: 2;
          }
        }
      }
    }

    .goods-lists-wrap {
      padding: 0 3.42rem;
    }

    .goods-lists {
      width: 100%;
      margin-bottom: 0.75rem;

      .goods-list-item {
        margin-bottom: 0.75rem;

        .common-list-title {
          height: 0.91rem;
          display: flex;
          align-content: space-between;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 0.6rem;

          .title-name {
            width: 100%;
            text-align: center;
            font-size: 0.52rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
          }

          .title-line {
            width: 0.59rem;
            height: 0.08rem;
            background: #003879;
          }
        }

        .list-box {
          display: flex;
          align-items: center;
          justify-content: start;
          flex-wrap: wrap;

          .list-item {
            width: 25%;
            flex-shrink: 0;
            padding: 0 0.3rem;
            box-sizing: border-box;
            margin-bottom: 0.75rem;

            .item-img {
              width: 100%;
              // height: 3.96rem;
              margin-bottom: 0.14rem;
              position: relative;

              @keyframes move {
                from {
                  transform: translateY(0.5rem);
                }

                to {
                  transform: translateY(0rem);
                }
              }

              .animation-img {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 99;
                transform: translateY(0.5rem);
                // transition: move 0.4s;
                // animation: move 1s forwards;
              }

              .animation-img-show {
                // --delay:2;
                animation: move 0.8s cubic-bezier(0.56, 0.09, 0.49, 2.77)
                  forwards var(--delay);
                // transform: translateY(0rem);
                // transition: move 0.4s;
              }
            }

            .name {
              margin-bottom: 0.11rem;
              font-size: 0.18rem;
            }

            .item-bottom {
              .bottom-price {
                text-decoration: line-through;
              }

              .bottom-add {
                // width: 1.26rem;
                // height: 0.54rem;
                padding: 0.1rem 0.06rem;
                flex-shrink: 0;
                background: #ffffff;
                box-shadow: 0px 0.04rem 0.04rem 0px rgba(0, 0, 0, 0.1);
                border-radius: 0.04rem;
                border: 1px solid rgba(0, 0, 0, 0.1);
                font-size: 0.12rem;
                font-weight: 900;
              }

              .bottom-add:hover {
                background: #5ba7ff;
                color: #fff;
              }
            }
          }
        }

        .list-box::after {
          content: "";
          width: 100%;
        }
      }
    }
  }
  .why-choose-me {
    padding: 0 3.1rem;
    margin-bottom: 1.4rem;
    .why-left {
      flex: 1;
      margin-right: 0.49rem;
      .why-row1 {
        margin-bottom: 0.3rem;
        .btn {
          width: fit-content;
          padding: 0.13rem 0.47rem;
          font-weight: 600;
          font-size: 0.24rem;
          color: #ffb100;
          background: #ffffff;
          box-shadow: 0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.1),
            0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.25);
          border-radius: 0.3rem;
        }
      }
      .why-row2 {
        font-weight: 600;
        font-size: 0.32rem;
        color: #000000;
        margin-bottom: 0.4rem;
      }
      .why-row3 {
        font-size: 0.24rem;
        color: #666666;
        margin-bottom: 0.49rem;
      }
      .why-row4 {
        margin-bottom: 0.19rem;
        .why-row4-item {
          margin-bottom: 0.41rem;
          .icon {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            box-shadow: 0px 0.04rem 0.04rem 0px rgba(0, 0, 0, 0.1);
            flex-shrink: 0;
            margin-right: 0.34rem;
            img {
              width: 0.4rem;
            }
          }
          .right-text {
            .title {
              margin-bottom: 0.04rem;
              font-weight: 600;
              font-size: 0.28rem;
              color: #000000;
            }
            .content {
              font-size: 0.2rem;
              color: #666666;
            }
          }
        }
      }
      .why-row5 {
        .btn {
          width: fit-content;
          padding: 0.13rem 0.33rem;
          font-weight: 600;
          font-size: 0.24rem;
          color: #fff;
          background: #ffb100;
          border-radius: 0.3rem;
        }
      }
    }
    .why-right {
      width: 6.1rem;
      flex-shrink: 0;
      height: 6.56rem;
    }
  }
  .star-box {
    padding: 0 3.1rem;
    .star-row1 {
      margin-bottom: 0.3rem;
      .btn {
        width: fit-content;
        padding: 0.13rem 0.47rem;
        font-weight: 600;
        font-size: 0.24rem;
        color: #ffb100;
        background: #ffffff;
        box-shadow: 0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.1),
          0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.25);
        border-radius: 0.3rem;
      }
    }
    .star-row2 {
      font-weight: 600;
      font-size: 0.32rem;
      color: #000000;
      text-align: center;
      margin-bottom: 0.4rem;
    }
    .star-row3 {
      font-size: 0.24rem;
      color: #666666;
      text-align: center;
      margin-bottom: 0.8rem;
    }
    .star-row4 {
      .star-box-list {
        .star-box-item {
          width: 4.2rem;
          // height: 5.19rem;
          border-radius: 0.16rem;
          border: 0.02rem solid #e1e1e1;
          padding: 0.4rem;
          box-sizing: border-box;
          &:hover {
            box-shadow: 0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.1),
              0px 0.04rem 0.1rem 0px rgba(139, 139, 139, 0.25);
          }
          .star-item-row1 {
            margin-bottom: 0.3rem;
            .header-img-box {
              position: relative;
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              margin-right: 0.4rem;
              .float-img {
                position: absolute;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                right: -0.2rem;
                top: 0.16rem;
              }
            }
            .content-box {
              .title {
                font-weight: 600;
                font-size: 0.24rem;
                color: #000000;
                margin-bottom: 0.06rem;
              }
              .content {
                font-size: 0.2rem;
                color: #666666;
              }
            }
          }
          .star-item-row2 {
            font-size: 0.2rem;
            color: #666666;
            margin-bottom: 0.3rem;
            line-height: 0.39rem;
          }
          .star-item-row3 {
            width: 100%;
            height: 0.02rem;
            background: #d9d9d9;
            margin-bottom: 0.3rem;
          }
          .star-item-row4 {
            .star-item-left {
              width: 1.24rem;
              height: 0.52rem;
              margin-right: 0.64rem;
              // border: 0.01rem solid #f2e9e9;
            }
            .star-item-right {
              img {
                width: 1.52rem;
                height: 0.24rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
